import { message } from 'antd';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { ChatService } from '../../services/chat.service';

export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_PUSHER = 'SET_PUSHER';
export const SET_LOADING = 'SET_LOADING';
export const SET_ROOMS = 'SET_ROOMS';
export const UPDATE_ROOMS = 'UPDATE_ROOMS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SET_NEW_ROOMS = 'SET_NEW_ROOMS';
export const SET_ALLMESSAGESOFCHAT = 'SET_ALLMESSAGESOFCHAT';
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';
export const SET_CLIENT_NEW_MESSAGE = 'SET_CLIENT_NEW_MESSAGE';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENTS_LOADING = 'SET_CLIENTS_LOADING';

export const fetchRooms = (page, resetData = false) => async dispatch => {
  dispatch(setLoading(true));

  const res = await ChatService.getAllChats(page);

  await dispatch({
    type: SET_ROOMS,
    payload: { data: res.data, resetData },
  });

  dispatch(setLoading(false));
  return res.data;
};

export const fetchAllMessages = (
  roomId,
  page,
  resetData = false,
) => async dispatch => {
  const res = await ChatService.getAllMessages(roomId, page);

  await dispatch({
    type: SET_ALLMESSAGESOFCHAT,
    payload: { data: res.data, resetData },
  });
  return res.data;
};

export const setClientsLoading = loading => ({
  type: SET_CLIENTS_LOADING,
  payload: loading,
});

export const fetchClients = params => async dispatch => {
  dispatch(setClientsLoading(true));

  const res = await ChatService.getClients(params);

  await dispatch({
    type: SET_CLIENTS,
    payload: res.data,
  });

  dispatch(setClientsLoading(false));
  return res.data;
};

export const setConnected = connected => ({
  type: SET_CONNECTED,
  payload: connected,
});

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: loading,
});

export const setNewRoom = chat => ({
  type: SET_NEW_ROOMS,
  payload: chat,
});

export const updateRooms = rooms => ({
  type: UPDATE_ROOMS,
  payload: rooms,
});

export const setMessage = (message, removeLoading = false) => ({
  type: SET_MESSAGE,
  payload: { message, removeLoading },
});

export const updateMessage = message => ({
  type: UPDATE_MESSAGE,
  payload: message,
});

export const setClientNewMessage = data => ({
  type: SET_CLIENT_NEW_MESSAGE,
  payload: data,
});

export const setPusher = pusher => ({
  type: SET_PUSHER,
  payload: pusher,
});

export const initializeChatSocket = () => dispatch => {
  const pusher = new Pusher('ABCDEFG', {
    cluster: 'mt1',
    wsHost: 'api.turonexpress.com',
    wsPort: 443,
    forceTLS: true,
  });

  const echo = new Echo({
    broadcaster: 'pusher',
    client: pusher,
  });

  echo.connector.pusher.connection.bind('connected', () => {
    dispatch(setConnected(true));
    console.info('Подключение к серверу TelegramMessageEvent!');
    message.success('Подключение к серверу TelegramMessageEvent!');
  });

  echo.connector.pusher.connection.bind('disconnected', () => {
    dispatch(setConnected(false));
    console.error('Нет связи с сервером!');
    message.error('Нет связи с сервером!!!');
  });

  echo.connector.pusher.connection.bind('state_change', states => {
    if (states.current === 'disconnected') {
      echo.connector.pusher.connect();
    }
  });

  echo.channel('telegram').listen('TelegramMessageEvent', e => {
    if (e?.chat_id) {
      if (e?.mode === 'client_new_message') {
        dispatch(setClientNewMessage(e?.data));
      }
    } else {
      message.error(e?.message ?? 'Error');
    }
  });

  dispatch(setPusher(pusher));
};

export const initializeNewChatEvent = () => dispatch => {
  const pusher = new Pusher('ABCDEFG', {
    cluster: 'mt1',
    wsHost: 'api.turonexpress.com',
    wsPort: 443,
    forceTLS: true,
  });

  const echo = new Echo({
    broadcaster: 'pusher',
    client: pusher,
  });

  echo.connector.pusher.connection.bind('connected', () => {
    dispatch(setConnected(true));
    console.info('Подключение к серверу NewChatEvent!');
    message.success('Подключение к серверу NewChatEvent!');
  });

  echo.connector.pusher.connection.bind('disconnected', () => {
    dispatch(setConnected(false));
    console.error('Нет связи с сервером!');
    message.error('Нет связи с сервером!!!');
  });

  echo.connector.pusher.connection.bind('state_change', states => {
    if (states.current === 'disconnected') {
      echo.connector.pusher.connect();
    }
  });

  echo.channel('newchat').listen('NewChatEvent', e => {
    dispatch(setNewRoom(e));
  });

  dispatch(setPusher(pusher));
};
